import React, { useState } from "react";
import clsx from "clsx";
import { Text, Container, Icon, Button } from "@atoms";
import { AppLink } from "@base";
import { MobileHeader, LoginModal } from "@molecules";
import { useAppState } from "@state";
import { m } from "framer-motion";
import useHeaderData from "@staticQueries/HeaderQuery";
import LogoSvg from "@svg/logo.svg";

const Logo = () => {
  return (
    <span className="icon icon--fit-height block h-10 w-auto">
      <LogoSvg />
    </span>
  );
};

const HeaderHover = ({ text, url, children }) => {
  const [visible, setVisible] = useState(false);
  const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  };

  return (
    <li
      className="relative h-full items-center bg-white"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <div className="flex cursor-pointer flex-row items-center text-xs font-bold text-purple underline decoration-teal/0 underline-offset-4 transition duration-200">
        <span className="whitespace-nowrap">{text}</span>
        {children?.length > 0 && (
          <Icon
            name="chevron"
            className="ml-1 h-2.5 w-2.5 rotate-90 text-teal"
          />
        )}
      </div>
      {children?.length > 0 && (
        <m.ul
          className={clsx(
            "absolute -left-3.5 w-max space-y-4 rounded-b-2xl bg-white p-4 pt-8",
            { "pointer-events-none": !visible }
          )}
          animate={visible ? "open" : "closed"}
          initial="closed"
          variants={variants}
          transition={{ duration: 0.3 }}
        >
          {children?.map((subLink, _i) => {
            const { text: _text, url: _url } = subLink;
            return (
              <li key={subLink.uid}>
                <AppLink
                  to={_url}
                  className="text-xs font-bold text-purple underline decoration-teal/0 underline-offset-4 transition duration-200 hover:decoration-teal"
                  activeClassName="decoration-teal"
                >
                  {_text}
                </AppLink>
              </li>
            );
          })}
        </m.ul>
      )}
    </li>
  );
};

const Header = () => {
  const [{ layout }, dispatch] = useAppState();

  // Mobile nav triggers
  const { hideNav } = layout;

  // Defaults for SVG Hamburger Icon animation
  const iconStyles = {
    animate: hideNav ? "closed" : "open",
    stroke: "currentColor",
    strokeWidth: "4",
    strokeLinecap: "round",
  };

  // Placeholder to be replaced with useHeaderData
  // or other static queries
  const { nav } = useHeaderData();

  // specific ease in inOutCubic
  const ease = [0.65, 0, 0.35, 1];

  const openModal = () =>
    dispatch({
      type: "openModal",
      title: "What kind of user are you?",
      description: `Login prompt.`,
      // eslint-disable-next-line react/no-unstable-nested-components
      content: React.memo(() => <LoginModal />),
      hideButton: true,
    });

  return (
    <header className="relative z-50 w-full">
      <Container padding>
        <div className="flex h-16 items-center justify-between sm:h-20">
          <AppLink to="/" className="my-5 ">
            <Logo name="logo" fitHeight className="h-10" />
          </AppLink>
          <nav aria-label="Header navigation">
            <ul className="hidden items-center gap-3.5 lg:flex xl:gap-5">
              {nav?.map((link, i) => {
                const { text, button, color, outline } = link;

                if (link.url === "LOGIN") {
                  return (
                    <button
                      key={link.uid}
                      className="mr-2 py-5 text-xs font-bold text-purple"
                      type="button"
                      onClick={openModal}
                    >
                      {text}
                    </button>
                  );
                }

                if (button) {
                  return (
                    <li key={link.uid}>
                      <Button
                        to={link.url}
                        size="sm"
                        color={color || "purple"}
                        outline={outline}
                      >
                        <Text>{text}</Text>
                      </Button>
                    </li>
                  );
                }

                return <HeaderHover key={link.uid} {...link} />;
              })}
            </ul>

            <button
              type="button"
              className="flex lg:hidden"
              aria-label="Toggle mobile navigation"
              onClick={() => {
                dispatch({
                  type: hideNav ? "showNav" : "hideNav",
                });
              }}
            >
              {/* Hamburger Menu with animated SVG */}
              <span className="icon w-6 text-slate">
                <svg width="28" height="28" viewBox="0 0 34 24">
                  <m.path
                    {...iconStyles}
                    variants={{
                      closed: { d: "M32 2L2 2" },
                      open: { d: "M31 2L9 22" },
                    }}
                    transition={{ ease }}
                  />
                  <m.path
                    {...iconStyles}
                    d="M32 12L2 12"
                    variants={{
                      closed: { opacity: 1 },
                      open: { opacity: 0 },
                    }}
                    transition={{ duration: 0.2, ease }}
                  />
                  <m.path
                    {...iconStyles}
                    variants={{
                      closed: { d: "M32 22L2 22" },
                      open: { d: "M31 22L9 2" },
                    }}
                    transition={{ ease }}
                  />
                </svg>
              </span>
            </button>
          </nav>
        </div>
      </Container>
      <MobileHeader nav={nav} openModal={openModal} />
    </header>
  );
};

Header.defaultProps = {};

export default Header;
